<template>
  <div class="container">
    <div class="text-center pt-3">
      <h3 class="fellpunzel-gold">Termin via Telefon</h3>
      Rufen Sie uns direkt an und vereinbaren einen Termin
      <div class="p-2">
        <b-button href="tel:+491784884448" variant="outline-fellpunzel"
          ><b-icon icon="telephone" /> 01784884448</b-button
        >
      </div>
    </div>
    <hr class="mt-2 mb-3" />
    <div class="text-center">
      <h3 class="fellpunzel-gold">Termin online buchen</h3>
      Machen Sie einen Online-Termin in dem Sie das nachfolgende Formular
      ausfüllen und die Terminanfrage abschicken
    </div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-card
        class="mt-3"
        header="Terminanfrage"
        header-bg-variant="fellpunzel"
        header-text-variant="light"
      >
        <label for="example-i18n-picker">Wunschtermin wählen:</label>
        <b-form-datepicker
          id="example-i18n-picker"
          v-model="form.termin.datum"
          v-bind="labels"
          :locale="'de'"
          :start-weekday="1"
          :show-decade-nav="false"
          :hide-header="true"
          class="mb-2"
        ></b-form-datepicker>
        <!-- Termin Uhrzeit -->
        <b-form-group
          id="input-group-uhrzeit"
          label="Bevorzugte Uhrzeit"
          label-for="input-uhrzeit"
        >
          <b-form-select
            id="input-uhrzeit"
            v-model="form.termin.uhrzeit"
            :options="uhrzeitAuswahl"
            required
          ></b-form-select>
        </b-form-group>
        <!-- Hunderasse -->
        <b-form-group
          id="input-group-hunderasse"
          label="Hunderasse"
          label-for="input-hunderasse"
        >
          <b-form-input
            id="input-hunderasse"
            v-model="form.dogType.hunderasse"
            required
          ></b-form-input>
        </b-form-group>
        <!-- Name des Hundes -->
        <b-form-group
          id="input-group-nameHund"
          label="Name ihres Hundes"
          label-for="input-nameHund"
        >
          <b-form-input
            id="input-nameHund"
            v-model="form.dogType.nameHund"
            required
          ></b-form-input>
        </b-form-group>
        <!-- Vorname -->
        <b-form-group
          id="input-group-vorname"
          label="Vorname"
          label-for="input-vorname"
        >
          <b-form-input
            id="input-vorname"
            v-model="form.nameType.vorname"
            required
          ></b-form-input>
        </b-form-group>
        <!-- Nachname -->
        <b-form-group
          id="input-group-nachname"
          label="Nachname"
          label-for="input-nachname"
        >
          <b-form-input
            id="input-nachname"
            v-model="form.nameType.nachname"
            required
          ></b-form-input>
        </b-form-group>

        <!-- Telefonnummer -->
        <b-form-group
          id="input-group-telefonNummer"
          label="Telefonnummer"
          label-for="input-telefonNummer"
          ><b-form-input
            id="input-telefonNummer"
            type="tel"
            v-model="form.kommType.telefonNummer"
        /></b-form-group>

        <!-- Email -->
        <b-form-group
          id="input-group-email"
          label="E-Mail"
          label-for="input-email"
          ><b-form-input
            id="input-email"
            type="email"
            v-model="form.kommType.email"
            required
        /></b-form-group>
        <b-form-textarea
          id="textarea"
          v-model="form.nachricht"
          placeholder="Zusätliche Informationen zu Ihrer Terminanfrage..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-card>

      <div class="pt-3 pb-3">
        <b-button type="submit" variant="outline-fellpunzel"
          >Terminanfrage abschicken</b-button
        >
      </div>
    </b-form>
    <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      variant="success"
      @dismiss-count-down="countDownChanged"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
    >
      Vielen Dank für Ihre Anfrage!<br />
      Das Fellpunzel Team wird Ihren Termin schnellst möglich via Mail oder
      Anruf bestätigen.
    </b-alert>

    <!-- Form Data Result -->
    <!-- <h2 class="mt-5">Datencheck</h2>
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "KontaktComponent",
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,

      form: {
        termin: {
          datum: "",
          uhrzeit: "flexibel"
        },
        dogType: {
          hunderasse: "",
          nameHund: ""
        },
        nameType: {
          nachname: "",
          vorname: ""
        },

        kommType: {
          telefonNummer: null,
          email: ""
        },
        nachricht: ""
      },
      uhrzeitAuswahl: [
        {
          value: "flexibel",
          text: "flexibel"
        },
        { value: "08:30 - 12:00 Uhr", text: "08:30 - 12:00 Uhr" },
        { value: "08:30 - 12:00 Uhr", text: "08:30 - 12:00 Uhr" },
        { value: "12:00 - 16:00 Uhr", text: "12:00 - 16:00 Uhr" },
        { value: "16:30 - 20:00 Uhr", text: "16:30 - 20:00 Uhr" }
      ],
      //Labels für den Datepicker
      labels: {
        labelPrevDecade: "Vorheriges Jahrzehnt",
        labelPrevYear: "Vorheriges Jahr",
        labelPrevMonth: "Vorheriger Monat",
        labelCurrentMonth: "Aktueller Monat",
        labelNextMonth: "Nächster Monat",
        labelNextYear: "Nächstes Jahr",
        labelNextDecade: "Nächstes Jahrzehnt",
        labelToday: "Heute",
        labelSelected: "Ausgewähltes Datum",
        labelNoDateSelected: "Kein Datum gewählt",
        labelCalendar: "Kalender",
        labelNav: "Kalendernavigation",
        labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren"
      },

      show: true
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      // alert(JSON.stringify(this.form));
      this.sendEmail();
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.resetFields();

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    resetFields() {
      (this.form.termin.datum = ""),
        (this.form.termin.uhrzeit = ""),
        (this.form.dogType.hunderasse = ""),
        (this.form.dogType.nameHund = ""),
        (this.form.nameType.nachname = ""),
        (this.form.nameType.vorname = ""),
        (this.form.kommType.telefonNummer = null),
        (this.form.kommType.email = "");
      this.form.nachricht = "";
    },
    sendEmail() {
      var templateParams = {
        hunderasse: this.form.dogType.hunderasse,
        nameHund: this.form.dogType.nameHund,
        nachname: this.form.nameType.nachname,
        vorname: this.form.nameType.vorname,
        telefonnummer: this.form.kommType.telefonNummer,
        email: this.form.kommType.email,
        nachricht: this.form.nachricht,
        datum: this.form.termin.datum,
        uhrzeit: this.form.termin.uhrzeit
      };
      emailjs
        .send(
          "service_gmail",
          "template_fellpunzel",
          templateParams,
          "user_TrFtVBoXTlZKrcsn1PEUQ"
        )
        .then(
          function(response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function(error) {
            console.log("FAILED...", error);
          }
        );
      this.resetFields();
      this.showAlert();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }
  }
};
</script>
