<template>
  <div class="p-0">
    <KontaktComponent />
  </div>
</template>

<script>
import KontaktComponent from "@/components/KontaktComponent.vue";
export default {
  name: "Kontzakt",
  components: {
    KontaktComponent
  }
};
</script>
