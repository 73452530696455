<template>
  <div>
    <div class="text-center pt-3">
      <h1 class="fellpunzel-gold">Ein kleiner Einblick in den Salon</h1>
      Im Hundesalon Fellpunzel wird Ihr Hund in liebevoller und entspannter
      Atmosphäre artgerecht und kompetent gepflegt.
    </div>
    <div class="d-flex flex-wrap">
      <!-- col1 -->

      <div class="d-flex flex-column d-inline col-md-6 pt-3">
        <b-img thumbnail fluid-grow :src="image1" alt="Image 1"></b-img>
        <h3 class="fellpunzel-gold">Aufenthaltsbereich</h3>
        <p align="justify">
          Momentan ist es aufgrund der Corona-Einschränkungen nicht erlaubt,
          dass Sie während des Termins im Salon verweilen können. Nach Corona
          können Sie gerne im Salon bleiben und einen Kaffee oder Wasser
          trinken. Es ist jedoch anzumerken, dass es bei einigen Hunden besser
          ist, wenn Sie nicht vor Ort bleiben, da der Hund ansonsten zu sehr auf
          Sie fixiert ist und sich deshalb nicht entspannen kann.
        </p>
        <p align="justify">
          Sie müssen sich nicht schuldig fühlen, Ihr Haustier allein zu lassen.
          Für den perfekten Look ist das vielleicht sogar das Beste. Meine
          Leistungen im Bereich Haarschnitt bieten Ihrem Haustier eine
          umfassende Betreuung in Ihrer Abwesenheit.
        </p>
      </div>

      <!-- col2 -->
      <div class="col-md-6 pt-3 align-self-center">
        <h3 class="fellpunzel-gold">Schneide- & Föhnbereich</h3>
        <p align="justify">
          Der Schneide- und Föhnbereich ist immer für Sie einsehbar. Allerdings
          sind die meisten Fellnasen entspannter, wenn Herrchen und Frauchen
          außer Sicht sind. Hier wird ihr treuster Begleiter getrocknet und
          ausgebürstet. Die beste Grundlage für jeden Schnitt ist optimal
          gebürstetes und aus geföhntes Fell.
        </p>
        <b-img thumbnail fluid-grow :src="image2" alt="Image 1"></b-img>
      </div>

      <!-- col3 -->
      <div class="col-md-6 pt-3">
        <h3 class="fellpunzel-gold">Badebereich</h3>
        <p align="justify">
          Hier wird Ihr geliebter Vierbeiner schonend gewaschen und mit
          speziellen Produkten, die auf das Haarkleid Ihres Hundes abgestimmt
          sind versorgt.
        </p>
        <p align="justify">
          Sie haben bei uns die Möglichkeit, Ihren Hund selbst zu waschen. Kommt
          Ihr Liebling einmal dreckig vom Spielen Heim, dann kommen Sie zu uns
          und machen Sie bei uns das Bad dreckig. Ihr Bad bleibt sauber und muss
          nicht renoviert werden. Handtücher, Föhn und Pflegemittel sind
          selbstverständlich vorhanden.
        </p>
        <b-img thumbnail fluid-grow :src="image3" alt="Image 1"></b-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image1:
        "https://firebasestorage.googleapis.com/v0/b/fellpunzel.appspot.com/o/SalonBilder%2FWartebereich.jpeg?alt=media",
      image2:
        "https://firebasestorage.googleapis.com/v0/b/fellpunzel.appspot.com/o/SalonBilder%2FArbeitsbereich.jpeg?alt=media",
      image3:
        "https://firebasestorage.googleapis.com/v0/b/fellpunzel.appspot.com/o/SalonBilder%2FWaschbereich.jpeg?alt=media"
    };
  }
};
</script>
